import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Apply2 from "../commons/Apply2";

import TopFooter from "../commons/TopFooter";
import icon4 from "../../icons/akiltech.jpg"
import icon5 from "../../icons/email.jpg";



const WebsiteDevelopment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main>
      {/* <!-- Slider Area Start--> */}
      <div className="slider-area">
        <div className="single-sliders slider-height d-flex align-items-center">
          <div className="container position-relative">
            <div className="row align-items-center">
              <div className="col-xl-7 col-lg-7 col-md-6">
                <div className="hero-caption">
                  <h1>Custom Web Development</h1>

                  <p>
                    75% of people judge a business based on the appearance of its website.The right website will attract the right audience – which means increased traffic and conversions. Having a wonderfully designed website is useless if it’s not converting visitors into customers! Wondering how to make your mark online?</p>

                  <Link to="/services" className="btn hero-btn">
                    BOOK FREE BRIEFING SESSION
                  </Link>
                </div>
              </div>
              {/* <!-- hero shape 2--> */}

            </div>
          </div>
        </div>
        <div className="  d-flex ">
          <div className="container position-relative">
            <div className="row ">
              <div className="col-xl-5 col-lg-6 col-md-6  mouse_scroll  "></div>
              {/* <!-- hero shape 2--> */}
              <div className="">
                <div className="header-social text-white ">
                  <a className="text-white" href="#a">
                    Follow us on:
                  </a>
                  <a
                    href="https://www.facebook.com/akiltechspecialities/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="text-white fab fa-facebook-square"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/akiltech_inc/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className=" text-white fab fa-instagram-square"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/akiltech-incoparated"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="text-white fab fa-linkedin-in"></i>
                  </a>
                  <a
                    href="https://twitter.com/AkilTech_inc"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className=" text-white fab fa-twitter-square"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- social --> */}
      </div>

      {/* <!-- Slider Area End -->
    <!-- Services Area Start --> */}
      <section className="categories-area section-padding">
        <div className="container">
          <div className="row justify-content-center">

            <h2>Can Your Business Afford Not Having A Website ?</h2>
            <p className="mt-2">
              Websites are a frustrating pain point for many companies. Without a web presence, you automatically lose the opportunity
              to get in touch with your target audience and popularize your brand. The ability to create traffic, retain visitors, get return visitors, and convert
              leads is pivotal to the growth of all types of businesses—large
              and small, B2B and B2C, and every industry. First impressions matter, and what better way to create a positive one than by having a professionally designed and highly user-friendly website?
            </p>
            <div className="row mt-3"><div className="col-lg-6"><img className="img-fluid" src={icon4} /></div>
              <div className="col-lg-6">
                <p className="mt-3">
                  So because now you’re here, <strong>congratulations</strong> on taking your first
                  step in your journey to a better and useful website.
                  More and more consumers are taking to the internet to find products and services,
                  and <strong> without </strong>
                  a website you are invisible to them, leaving them no choice
                  but to go to your competitors. If your <strong>website problems seem overwhelming</strong>, take
                  a deep breath. In no time, your frustration
                  will shrink and you’ll be on your way to <a href="#process" id="lik">meeting your website
                    goals</a>. Who knows, you might even <strong>get excited about it!</strong>
                </p><p className="mt-3"> At Akiltech Inc. we
                  understand the importance of having a <strong>website for your business</strong>. That is why we create websites
                  that are <strong>optimized for conversion</strong>, giving you a website that is
                  both beautifully designed and extremely functional.
              
                </p>
                <div className="section-tittle text-center mb-60">
                  <h2>If you want a website that speaks your language, contact us today. </h2>

                  <a href="request" className="border-btn">
                    Talk To Us
                  </a>
                </div>
              </div></div>
          </div>




        </div>

      </section>

      <section class="services-area3  ">
        <div className="container">
          <div className="section-tittle  text-center mb-25">

            <h2 className="text-white" id="process">What Makes Us Different: Our Process !</h2>

          </div>

          <p class="  mb-5"> No two companies are the same, which is
            why we always have a discovery session approach to strive to meet each client’s unique needs and objectives
            .
            Does your online presence speak for itself? Is your offering so
            distinguishable that it will stand out amidst the crowd? Is it appealing to the right target audience? While the
            internet is an abundance of information and resources, it is filled
            with competition continually striving to outcompete you. Wondering
            how to make your mark online? Read on  </p>
          <div class="row  align-items-lg-center">







            <div class="col-lg-6 col-md-6 ">
              <h4 class="mb-3">1. Briefing  Session</h4>
              <p class="mb-0 ">We start development on your site by taking you
                through our brief session discussing  background of your business or idea, current situation, your expectations from our services and options available to move
                forward. The information given should be concise, factual, clear, substantiated and unbiased. If
                information is missing or unavailable, this should be noted. We make sure we clearly understand what you are looking for from our website development solutions, your needs in detail. </p>
            </div>

            <div class="col-lg-6 col-md-6 ">


              <h4 class="mb-3">2. Discovery Session</h4>
              <p class="mb-0 ">We will discuss your system ,user and business requirements
                in detail. A website might be great to look at, but the true win comes in when the correct audiences make it to the correct page.
                 And even after, focusing upon the user’s experience once they arrive. We
                  do this by identifying your business’ goals, as
                   well as those of your consumers. Our developers
                    will also discuss your brand identity, tone, and brand message. Personas, Information Architecture , Customer centric problem statements, Pain Points Statements , Userflow diagrams</p>

            </div>
          </div>
          <div class="row  align-items-lg-center">
            <div class="col-lg-6 col-md-6">


              <h4 class="mb-3">3. Content Creation & Website Design</h4>
              <p class="mb-0 ">Our copywriters are on hand to optimize any content received, as well as,
                 produce content for your website or blog. Killer web design, together with skilled content, is guaranteed to generate large sums of traffic your way. But what’s even better than bucket-loads of potential customers finding their way to you? The right kind of traffic. 
              A satisfied, guaranteed customer who’s actually going to stick around. Of which that traffic, transforms into increased revenue for you and your brand.</p>

            </div>
            <div class="col-lg-6 col-md-6">


              <h4 class="mb-3">4. Website Development</h4>
              <p class="mb-0 ">We create an online presence and creative identity for your brand.
               We also create a most enjoyable experience, for those interacting with you.
                One which will be remembered long after your clients have logged off. Our team of dedicated web developers handle every aspect on your behalf. Build consumer trust and gain market referrals with an interactive, custom web page design
                .</p>

            </div>
          </div>

          <p className="mt-3">  Your website is a big project. For good communication, results, and enjoyment of the
            process, look for a team that’s a good fit for your organization. This includes keeping servers running perfectly, handling website security and privacy, and maintaining your valuable databases. We do what it takes to guarantee you have a beautiful website that runs smoothly 24/7, 365.
          </p>
        </div>



      </section>
   
<div className="call-blue text-center">

<h2 className="text-white text-center mt-5">
Say goodbye to AMATEUR, OUTDATED
 & poor-performing websites

</h2>
<a href="https://calendly.com/mushaitapiwanashe/briefing-session" className="btn mt-20">
  Schedule a Meeting
</a>
</div>


      <section className="categories-area section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-6 col-md-10">
              {/* During our discovery session, we work together to identify
                       the key website performance metrics that are important to
                        your business. Using this information, we establish clear
                         goals and thoroughly assess and improve the performance
                          of your website. It is also about showcasing what is most important to your 
                       customers.<!-- Section Tittle -->Not all detail is appropriate for all audiences; consider who you are
writing for */}
              <div className="section-tittle text-center mb-25">
                <span>Websites That Sells</span>
                <h2>Proven Custom Web Development Solutions </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/custom.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Content Creation and Management</a>
                  </h5>
                  <p>
                    While the look and feel of your <strong>website</strong> help to establish its
                    identity, it’s your content that gives it a voice!


                    They say <strong>‘Content is King’</strong> for a reason – good content marketing will always be part of effective <strong>web development</strong>. Our ability to offer design, SEO and content services all in one sets us apart from other web design companies in Cape Town and Johannesburg.

                    Get in touch today if you’d like to find out more about our
                    comprehensive <strong>website design services</strong>.              </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ecom1.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Web Migration & Upgradation </a>
                  </h5>
                  <p>
                    Business apps and <strong>websites</strong> need constant upgradation to reinvigorate digital experiences. With our <strong>custom web migration and upgradation services</strong>, you
                     can make your existing business solutions result-driven and successful.
                      Upgrade your business website to the newest industry standards
                       and give your customers an amazing web experience. </p>
                </div>
              </div>
            </div>

          </div>
          <div className="row">




            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ecom1.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services"> Web Support & Maintenance Services </a>
                  </h5>
                  <p>
                    Developing a fully customized <strong>website</strong> shouldn't be
                     the sole aim of a business owner. Maintaining a <strong>mobile application or website</strong>
                      timely help to keep it running smoothly and allows businesses to withstand
                       competition. With our custom <strong>web support and maintenance services</strong>, we
                        can help your business website stay updated as per the ongoing market trends.  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ecom1.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services"> Web Development Consulting </a>
                  </h5>
                  <p>
                    If you have an idea to develop a <strong>custom website</strong> and
                     need professional assistance to execute it, we can help. Our team
                      will help you evaluate your business goals and requirements while
                       ensuring project feasibility and success. Seek our 
                        <strong>web development consulting services</strong> and turn your small
                         business idea into a successful opportunity.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">




            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ecom1.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">E-commerce Development </a>
                  </h5>
                  <p>
                    Across the globe, more and more consumers turn to online retailers every year. By 2027, the worldwide B2C e-commerce market size is expected to reach US$ 6.2 trillion. If your company wants to be in on the action, then you need a highly professional, <strong>user-friendly ecommerce site</strong> that
                     entices your potential customers to hit that ‘add to cart’ button. 
                     Our <strong>ecommerce web design agency</strong> will help you find
                      the perfect solution, and ensure it’s executed beautifully. Akiltech Inc.
                       offers world class <strong>ecommerce web design </strong>.    </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ecom1.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Website Design </a>
                  </h5>
                  <p>
                    It seem like a difficult decision for most of our clients to make
                    between pre-made templates and <strong>custom web design</strong>.Every business needs a website, but figuring
                    out how to go about it can be nerve-wracking.When it comes to truly
                    capturing your company’s essence in a website, it’s more than just picking the right
                    colors and pasting your logo. Whether you’re due for a redesign or need to create
                    a new site from scratch, there are three tiers of <strong>website creation</strong> to
                     consider. Sharing the same premade theme with countless
                      other businesses out there kind of goes against those principles. Going
                       the custom route allows you to dictate your wants and needs
                        on a granular level — not just adjusting colors and fonts, but
                         the actual structure and features of your site, as well </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">

            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/webservices.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">WordPress website design</a>
                  </h5>
                  <p>


                    While the basics are
                    very simple, if you want your <strong>WordPress-powered website</strong> to really
                    stand out, you need expert level skills and experience.

                    That’s where our <strong>WordPress website</strong> design service becomes invaluable. Whether you already have a WordPress based website and need help adding more functionality and plug-ins, or like the idea of a website you can add fresh content to easily, this service is perfect for you.  Another benefit for our clients is the ease with which they can
                    new content (like your latest blog post) themselves, without needing
                    a third party to upload it on your behalf.

                  </p>
                </div>
              </div>
            </div>



            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ecom1.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Blog Websites </a>
                  </h5>
                  <p>
                    We can create your blog and share your voice in a very short
                     period of time. A blog is an easy and cost-effective way to make
                      the most out of your organic Google (search engine) placements.

                    The blogs we create have built‑in <strong>search engine optimisation (SEO)</strong>, social
                     media integration, and sharing features. Make
                      use of our <strong>blog development services</strong> and reach
                       new readers & customers. With our 
                       responsive (mobile-friendly) <strong>blog design and development</strong>, your
                        blog visitors will enjoy a seamless experience on any device.</p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <p className="container mt-3">Imagine <strong>building a website</strong> like you would build your family home.
          Would you build a house by yourself? Would you hire newbies who build houses in their
          spare time? Or would you hire a team of expert professionals
          with years of skill in their respective fields to ensure quality
          care was taken with your home? You wouldn’t take shortcuts and freebies
          to build your family home. Why do that with your company’s virtual “home?” Isn’t your business – like your home -- worth the investment? Book a free briefing session and get some business web solutions from Akiltech Inc.
        </p>
      </section>

      {/* <!-- Services Area End -->
    <!---Start a project--> */} <div className="container-fluid call-bg">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-9  col-md-12">
            {/* <!-- Section Tittle --> */}
            <div className="section-tittle text-center mb-60">
              <h2>If you want a website that speaks your language, contact us today. </h2>

              <a href="request" className="border-btn2">
                Schedule Free Briefing Session
              </a>
            </div>
          </div>
        </div>
      </div>
      <section className="categories-area section-padding">
        <div className="row container">
          <div className="col-lg-6">
            
            <img className="img-fluid" src={icon5} />
            </div>
            <div className="col-lg-6">

            

              {/* <!-- Section Tittle --> */}
              <div className="section-tittle text-center mb-25 mt-3">
                <span>Why Search Engine Optimization is a Great Investment</span>
                <h2>A Website That Doesn't Bring Client Is Useless </h2>

              </div>

              <p>All business owners want more bang for their buck. All want better profit margins. SEO saves you money. Consider SEO as an employee: SEO never clocks out. SEO never calls in sick. SEO never takes a holiday. SEO works for you around the clock, even when you’re sleeping.

                No, SEO won’t answer the phone. But it will keep the phone ringing. SEO done right will funnel customers to your website and put them in front of your products, services and brand.</p>
            
          </div>


        </div>

      </section>
      <div
        style={{ marginTop: "0" }}
        className="count-down-area section-padding border-bottom"
      >

        <section className="about-area2 section-padding gray-bg">
          <div className="count-down-area section-padding border-bottom">
            <div className="container-fluid gray-bg">
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-9  col-md-12">

                  <div className="section-tittle text-center mb-60">
                    <h2>Interested in discussing a project? </h2>
                   
                    <a href="request" className="border-btn">
                      Get in Touch
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>

        <section className="categories-area section-padding">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-6 col-md-10">
                {/* <!-- Section Tittle --> */}
                <div className="section-tittle text-center mb-25">
                  <span>Why Search Engine Optimization is a Great Investment</span>
                  <h2>Frequently Bought Together </h2>

                </div>
              </div>
              <p><strong>Website + SEO</strong> (the sooner you start SEO on your website the better as the informatino architecture (IA) of your website is important to the structure development and ranking of your website. This is an ongoing process of small incremental changes which lead to small increments over time, but result in large gains.</p>
              <p><strong>Website + Google Ads</strong> (Often clients require a quick and effective solution to get traffic to their website. This can be achieved through Google Ads. It is a good option to get new business fast.)</p>
              <p><strong>Website + SEO + Google Ads</strong> (If your budget allows, this is the best combination for short term and long term goals for website traffic, leads or sales.)</p>
              <p>All business owners want more bang for their buck. All want better profit margins. SEO saves you money. Consider SEO as an employee: SEO never clocks out. SEO never calls in sick. SEO never takes a holiday. SEO works for you around the clock, even when you’re sleeping.

                No, SEO won’t answer the phone. But it will keep the phone ringing. SEO done right will funnel customers to your website and put them in front of your products, services and brand.</p>
            </div>
            <div className="row">






            </div>

          </div>

        </section>
        {/* <!-- About Area End -->
      <!--Services Area 2 --> */}
        <section className="services-area2 section-padding2">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-5 col-lg-5 col-md-6 col-sm-11">
                {/* <!-- Section Tittle --> */}
                <div className="section-tittle section-tittle2 mb-55">
                  <span>Industries We Serve</span>
                  <h2>Software Development services </h2>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-6 col-sm-11">
                {/* <!-- Section Tittle --> */}
                <div className="section-tittle section-tittle2 mb-55">
                  <p>
                    No matter the business, AkilTech has you covered with
                    industry compliant solutions, customized to your company’s
                    specific needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="single-cat mb-30 text-center">
                  <div className="cat-icon">
                    <img src="assets/img/icon/stack1.svg" alt="" />
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#r">Industries & Manufacturing</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="single-cat mb-30 text-center">
                  <div className="cat-icon">
                    <img src="assets/img/icon/stack2.svg" alt="" />
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#r">Education & Health</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="single-cat mb-30 text-center">
                  <div className="cat-icon">
                    <img src="assets/img/icon/stack3.svg" alt="" />
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#r">Retail & Ecommerce</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="single-cat mb-30 text-center">
                  <div className="cat-icon">
                    <img src="assets/img/icon/stack4.svg" alt="" />
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#e">Travel & Hospitality</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="single-cat mb-30 text-center">
                    <div className="cat-icon">
                      <img src="assets/img/icon/stack1.svg" alt="" />
                    </div>
                    <div className="cat-cap">
                      <h5>
                        <a href="#s">Media and Entertainment</a>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="single-cat mb-30 text-center">
                    <div className="cat-icon">
                      <img src="assets/img/icon/stack2.svg" alt="" />
                    </div>
                    <div className="cat-cap">
                      <h5>
                        <a href="#a">Public Services & FinTech</a>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="single-cat mb-30 text-center">
                    <div className="cat-icon">
                      <img src="assets/img/icon/stack3.svg" alt="" />
                    </div>
                    <div className="cat-cap">
                      <h5>
                        <a href="#a"> Software as a Service & SMEs</a>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="single-cat mb-30 text-center">
                    <div className="cat-icon">
                      <img src="assets/img/icon/stack4.svg" alt="" />
                    </div>
                    <div className="cat-cap">
                      <h5>
                        <a href="#a">Agriculture & Real Estate</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-xl-12">
                  <div className="section-tittle section-tittle3 text-center pt-35">
                    <p>
                      Need a Technology support or to discuss a project ?{" "}
                      <a href="request">Get Started Now</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Apply2 />
      </div>


      <TopFooter />
    </main>
  );
};

export default WebsiteDevelopment;
