import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Apply2 from "../commons/Apply2";

import TopFooter from "../commons/TopFooter";

const CloudServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main>
      {/* <!-- Slider Area Start--> */}
      <div className="slider-area">
        <div className="single-sliders slider-height d-flex align-items-center">
          <div className="container position-relative">
            <div className="row align-items-center">
              <div className="col-xl-5 col-lg-6 col-md-6">
                <div className="hero-caption">
                  <h1>Search Engine Optimization</h1>

                  <p>
                  Invest in Your Future with an SEO Company that Gets Results.Akiltech Inc. helps small, medium and large businesses to rank as HIGH as possible on Google.
                  </p>
                  <ul>
                    <li> Increase your website traffic



</li>
                    <li> Get more leads & customers</li>
            
                    <li>Spend less on paid ads</li>
                  </ul>
                  <Link to="/services" className="btn hero-btn">
                  GET MY FREE PROPOSAL 
                  </Link>
                </div>
              </div>
              {/* <!-- hero shape 2--> */}
              <div className="hero-shape">
                <img src="assets/img/hero/hero-img.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="  d-flex ">
          <div className="container position-relative">
            <div className="row ">
              <div className="col-xl-5 col-lg-6 col-md-6  mouse_scroll  "></div>
              {/* <!-- hero shape 2--> */}
              <div className="">
                <div className="header-social text-white ">
                  <a className="text-white" href="#a">
                    Follow us on:
                  </a>
                  <a
                    href="https://www.facebook.com/akiltechspecialities/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="text-white fab fa-facebook-square"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/akiltech_inc/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className=" text-white fab fa-instagram-square"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/akiltech-incoparated"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="text-white fab fa-linkedin-in"></i>
                  </a>
                  <a
                    href="https://twitter.com/AkilTech_inc"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className=" text-white fab fa-twitter-square"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- social --> */}
      </div>

      {/* <!-- Slider Area End -->
    <!-- Services Area Start --> */}
      <section className="categories-area section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-6 col-md-10">
              {/* <!-- Section Tittle --> */}
              <div className="section-tittle text-center mb-25">
                <span>What We Do</span>
                <h2>Turn Your Website to be your number 1 Salesman </h2>
              
              </div>
            </div>
            <p>Whether your business is early in its journey or well on its way to digital transformation, our cloud solutions and technologies help charter a path to success. Whether you’re migrating legacy or building new cloud-native applications, reap the benefits of having STC manage your infrastructure. Our fully managed infrastructure frees up your developers by eliminating cumbersome tasks. Strategic partnerships with leading open source-centric companies in data management and analytics allow us to tightly integrate an array of services into the cloud, making it easy for enterprise customers to build and use apps in the public cloud. Whether you need an extra hand or hands-on support, our technology teams can help you plan, develop and launch the solutions that drive your most important business goals</p>
          </div>
          <div className="row">
            
           
          
          
           
          
          </div>
          <p>
            Regardless of the size of the project,our expert software engineers,
            developers and UI/UX designers will be there to guide you through
            your needs.We provide our services in different languages
            ,frameworks ,tools,databases , libraries .We believe in customizing
            each project based on special feature requirements and market
            conditions at competitive rates that's within your budget.
          </p>
          <a href="services" className="btn mt-20">
            Read More
          </a>
        </div>
        
      </section>
      <div className="container-fluid call-bg">
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-9  col-md-12">
                  {/* <!-- Section Tittle --> */}
                  <div className="section-tittle text-center mb-60">
                    <h2>You’ve Finally Found the Right SEO Company With Answers </h2>
                    <p className="mb-55">
                    Akiltech Inc has the SEO solutions you’ve been searching for. Don’t let another day go by where your competitors are looking down on you! It’s time to be discovered!
                    </p>
                    <a href="request" className="border-btn">
                      Get Clients with SEO
                    </a>
                  </div>
                </div>
              </div>
            </div>
      <section className="categories-area section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-6 col-md-10">
              {/* <!-- Section Tittle --> */}
              <div className="section-tittle text-center mb-25">
                <span>Our SEO Services</span>
                <h2>Increase Your Search Rankings and Get Discovered More Online </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/custom.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Amazon EC2</a>
                  </h5>
                  <p>
                  Amazon Elastic Compute Cloud is a web service that provides secure, resizable compute capacity in the cloud, offering flexibility to choose the appropriate mix of resources for your applications.                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/mobile.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">AWS WorkSpaces</a>
                  </h5>
                  <p>
                  Amazon WorkSpaces is a managed, secure Desktop-as-a-Service (DaaS) solution. You can use Amazon WorkSpaces to provide thousands of desktops to workers across the globe.                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ux.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Amazon Chime</a>
                  </h5>
                  <p>
                  Amazon Chime is a communications service used to chat, video calling, screen sharing and place business calls inside and outside your organization, all using a single application.                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/webservices.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Amazon S3</a>
                  </h5>
                  <p>
                  Amazon Simple Storage Service (Amazon S3) is an object storage service that offers industry-leading scalability, availability, security, and performance.

                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/api.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">AWS Lambda</a>
                  </h5>
                  <p>
                  AWS Lambda lets you run code without provisioning or managing servers. automatically manages the computing resources required by that code.                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ecom1.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Amazon Lightsail </a>
                  </h5>
                  <p>
                  Lightsail is an easy-to-use cloud platform that offers you everything needed to build an application or website, plus a cost-effective, monthly plan.                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ecom1.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Amazon Aurora </a>
                  </h5>
                  <p>
                  Amazon Aurora is a MySQL and PostgreSQL-compatible relational database built for the cloud, that combines the performance and availability of traditional enterprise databases with the simplicity and cost-effectiveness of open source databases.    </p>            </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ecom1.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Amazon DynamoDB </a>
                  </h5>
                  <p>
                  Amazon DynamoDB is a key-value and document database that delivers single-digit millisecond performance at any scale. It can handle more than 10 trillion requests per day and support peaks of more than 20 million requests per second.    </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ecom1.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Amazon VPC</a>
                  </h5>
                  <p>
                  Amazon Virtual Private Cloud (Amazon VPC) enables you to launch Amazon Web Services resources into a virtual network you've defined. This virtual network resembles a traditional network operating in your own data center.   </p>
                </div>
              </div>
            </div>
          </div>
          
          <a href="services" className="btn mt-20">
            Read More
          </a>
        </div>
        
      </section>
     
      {/* <!-- Services Area End -->
    <!---Start a project--> */}
      <div
        style={{ marginTop: "0" }}
        className="count-down-area section-padding border-bottom"
      >
        {/* <!--End Your Project-->
      <!-- About Area  --> */}
        <section className="about-area2 section-padding gray-bg">
          <div className="count-down-area section-padding border-bottom">
            <div className="container-fluid gray-bg">
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-9  col-md-12">
                  {/* <!-- Section Tittle --> */}
                  <div className="section-tittle text-center mb-60">
                    <h2>Interested in discussing a project? </h2>
                    <p className="mb-55">
                      We accelerate the development of tech ecosystems and help
                      founders bring their ideas to life. In doing so, we create
                      opportunities for the right people to come together to
                      solve the problems of today, and prevent the ones of
                      tomorrow.
                    </p>
                    <a href="request" className="border-btn">
                      Get in Touch
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-5 col-lg-5 col-md-10">
                {/* <!-- about-img --> */}
                <div className="about-img">
                  <img src="assets/img/gallery/about1.jpg" alt="" />
                </div>
                <div className="ratting-point">
                  <div className="features-ratting">
                    <img src="assets/img/icon/notification.svg" alt="" />
                  </div>
                  <div className="features-caption">
                    <h3>Data Insights</h3>
                    <p>we create relevant and engaging content</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 col-md-10">
                <div className="about-caption mb-50">
                  {/* <!-- Section Tittle --> */}
                  <div className="section-tittle mb-50">
                    <h2>
                    Be #1 & Rank
<br />
  Higher on Google !
                    </h2>
                  </div>
                  <div className="single-offers mb-20">
                    <div className="offers-cap">
                      <img src="assets/img/icon/notification1.svg" alt="" />
                      <h3>
                        <a href="#digital_marketing">Digital Marketing</a>
                      </h3>
                      <p>
                        Build your company’s online presence with a responsive
                        website suitable for all devices. Drive organic traffic
                        on keywords that matter to your business
                      </p>
                    </div>
                  </div>
                  <div className="single-offers">
                    <div className="offers-cap">
                      <img src="assets/img/icon/notification1.svg" alt="" />
                      <h3>
                        <a href="#google">Google Analytics</a>
                      </h3>
                      <p>
                        We implement Google Analytics on your website or app in
                        order to gather and measure critical data flows by your
                        website visitors to gain a deeper understanding of user
                        behavior and experience.
                      </p>
                    </div>
                  </div>
                  <a href="services" className="btn mt-20">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section className="categories-area section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-6 col-md-10">
              {/* <!-- Section Tittle --> */}
              <div className="section-tittle text-center mb-25">
                <span>Why Search Engine Optimization is a Great Investment</span>
                <h2>We Practice What We Preach to Provide Maximum ROI </h2>
              
              </div>
            </div>
            <p>All business owners want more bang for their buck. All want better profit margins. SEO saves you money. Consider SEO as an employee: SEO never clocks out. SEO never calls in sick. SEO never takes a holiday. SEO works for you around the clock, even when you’re sleeping.

No, SEO won’t answer the phone. But it will keep the phone ringing. SEO done right will funnel customers to your website and put them in front of your products, services and brand.</p>
          </div>
          <div className="row">
            
           
          
          
           
          
          </div>
          <p>
            Regardless of the size of the project,our expert software engineers,
            developers and UI/UX designers will be there to guide you through
            your needs.We provide our services in different languages
            ,frameworks ,tools,databases , libraries .We believe in customizing
            each project based on special feature requirements and market
            conditions at competitive rates that's within your budget.
          </p>
          <a href="services" className="btn mt-20">
            Read More
          </a>
        </div>
        
      </section>
        {/* <!-- About Area End -->
      <!--Services Area 2 --> */}
        <section className="services-area2 section-padding2">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-5 col-lg-5 col-md-6 col-sm-11">
                {/* <!-- Section Tittle --> */}
                <div className="section-tittle section-tittle2 mb-55">
                  <span>Industries We Serve</span>
                  <h2>Software Development services </h2>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-6 col-sm-11">
                {/* <!-- Section Tittle --> */}
                <div className="section-tittle section-tittle2 mb-55">
                  <p>
                    No matter the business, AkilTech has you covered with
                    industry compliant solutions, customized to your company’s
                    specific needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="single-cat mb-30 text-center">
                  <div className="cat-icon">
                    <img src="assets/img/icon/stack1.svg" alt="" />
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#r">Industries & Manufacturing</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="single-cat mb-30 text-center">
                  <div className="cat-icon">
                    <img src="assets/img/icon/stack2.svg" alt="" />
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#r">Education & Health</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="single-cat mb-30 text-center">
                  <div className="cat-icon">
                    <img src="assets/img/icon/stack3.svg" alt="" />
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#r">Retail & Ecommerce</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="single-cat mb-30 text-center">
                  <div className="cat-icon">
                    <img src="assets/img/icon/stack4.svg" alt="" />
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#e">Travel & Hospitality</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="single-cat mb-30 text-center">
                    <div className="cat-icon">
                      <img src="assets/img/icon/stack1.svg" alt="" />
                    </div>
                    <div className="cat-cap">
                      <h5>
                        <a href="#s">Media and Entertainment</a>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="single-cat mb-30 text-center">
                    <div className="cat-icon">
                      <img src="assets/img/icon/stack2.svg" alt="" />
                    </div>
                    <div className="cat-cap">
                      <h5>
                        <a href="#a">Public Services & FinTech</a>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="single-cat mb-30 text-center">
                    <div className="cat-icon">
                      <img src="assets/img/icon/stack3.svg" alt="" />
                    </div>
                    <div className="cat-cap">
                      <h5>
                        <a href="#a"> Software as a Service & SMEs</a>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="single-cat mb-30 text-center">
                    <div className="cat-icon">
                      <img src="assets/img/icon/stack4.svg" alt="" />
                    </div>
                    <div className="cat-cap">
                      <h5>
                        <a href="#a">Agriculture & Real Estate</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-xl-12">
                  <div className="section-tittle section-tittle3 text-center pt-35">
                    <p>
                      Need a Technology support or to discuss a project ?{" "}
                      <a href="request">Get Started Now</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Apply2 />
      </div>
      

      <TopFooter />
    </main>
  );
};

export default CloudServices;
