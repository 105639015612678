import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import icon4 from "../../icons/akiltech.jpg"
import Apply2 from "../commons/Apply2";


import TopFooter from "../commons/TopFooter";




const GoogleAds = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main>
      {/* <!-- Hero area Start--> */}
      <div className="slider-area">
        <div className="single-sliders slider-height d-flex align-items-center">
          <div className="container position-relative">
            <div className="row align-items-center">
              <div className="col-xl-7 col-lg-7 col-md-6">
                <div className="hero-caption">
                  <h1>Google Ads</h1>

                  <p>
                    Getting your website to rank at the top of Google for your most important search queries usually takes months, if not years. Google Ads is a fantastic way to position your business at the top of the search results straight away.
                  </p>
                  <ul>
                    <li> Increase your website traffic



                    </li>
                    <li> Get more leads & customers</li>


                  </ul>
                  <Link to="https://calendly.com/mushaitapiwanashe/briefing-session" className="btn hero-btn">
                    GET MY FREE PROPOSAL
                  </Link>
                </div>
              </div>
              {/* <!-- hero shape 2--> */}

            </div>
          </div>
        </div>
        <div className="  d-flex ">
          <div className="container position-relative">
            <div className="row ">
              <div className="col-xl-5 col-lg-6 col-md-6  mouse_scroll  "></div>
              {/* <!-- hero shape 2--> */}
              <div className="">
                <div className="header-social text-white ">
                  <a className="text-white" href="#a">
                    Follow us on:
                  </a>
                  <a
                    href="https://www.facebook.com/akiltechspecialities/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="text-white fab fa-facebook-square"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/akiltech_inc/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className=" text-white fab fa-instagram-square"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/akiltech-incoparated"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="text-white fab fa-linkedin-in"></i>
                  </a>
                  <a
                    href="https://twitter.com/AkilTech_inc"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className=" text-white fab fa-twitter-square"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- social --> */}
      </div>
      <section className="categories-area section-padding">
        <div className="container">
          <div className="row justify-content-center">

            <h2>Can Your Business Afford Not Having A Website ?</h2>
            <p className="mt-2">
              Make no mistake, Google Ads is an incredibly complex platform and is sadly ill understood and significantly underestimated by many generalist marketing agencies and in-house marketers. Specialists exist in law, health, medicine, finance, so why not in digital marketing? We hold Google Ads strategy and management in the same esteem as studying for a PhD to find answers to even the most complex problems in business and we truly respect this digital marketing channel.
            </p>
            <div className="row mt-3"><div className="col-lg-6"><img className="img-fluid" src={icon4} /></div>
              <div className="col-lg-6">
                <p className="mt-3">
                  Our Google Ads service simply means, we will monitor, adapt, improve and report on your ongoing Google Ads account.

                  Managing Google Ads accounts is time consuming. If you don’t really understand the data, you could quickly find your account becoming chaotic.
                </p><p className="mt-3"> That means, wasted spend, wasted time and losing out to competitors.

                  Our expert team is here to assist you with managing your Google Ads account and help your business grow.

                </p>
                <div className="section-tittle text-center mb-60">
                  <h2>We’d Love To Hear From You To Discuss Your Google Marketing </h2>

                  <a href="request" className="border-btn">
                    Book Free Session
                  </a>
                </div>
              </div></div>
          </div>




        </div>

      </section>
      {/* <!--  Hero area End --> */}
      <div className="container">

        <div className="row mt-50 mb-50">
          <div className="col-md align-items-center">
            <h2>Easily control budget and ad spend</h2>
            <p>
              Thanks to built-in financial controls, you can rest assured we’ll keep your campaigns in budget. No nasty surprises and unforeseen expenses here!
            </p>

          </div>
          <div className="col-md align-items-center">
            <h2>Focus your marketing efforts on ready-to-buy customers</h2>
            <p>
              One of the most powerful aspects of Google Adwords marketing is its ability to match consumer intent with relevant offerings. Reach people who are genuinely looking to buy what you’re selling.
            </p>

          </div>
          <div className="col-md  ">
            <h2>Leverage Local</h2>
            <p className="text-left">
              Choose to have your ads displayed to potential customers in your area, during your operating hours. You can even target consumers searching on their mobile devices while they’re in close proximity to your premises.
            </p>
          </div>
        </div>
        <div className="row mt-50 mb-50">
          <div className="col-md align-items-center">
            <h2>Lead Generation</h2>
            <p>
              we totally understand the challenges of attracting the right targeted audiences to your website, and once there, enticing them to meaningfully engage with one of your website’s touchpoints such as the completion of a contact form, downloading a white paper or requesting a free trial of your service.
            </p>

          </div>
          <div className="col-md align-items-center">
            <h2>Focus your marketing efforts on ready-to-buy customers</h2>
            <p>
              One of the most powerful aspects of Google Adwords marketing is its ability to match consumer intent with relevant offerings. Reach people who are genuinely looking to buy what you’re selling.
            </p>

          </div>
          <div className="col-md  ">
            <h2>Leverage Local</h2>
            <p className="text-left">
              Our aim is to lead
              digital transformation in Africa. We assist organizations in
              accelerating their “Digital Transformation”, "Digital Growth" and "Digital Innovation" by
              leveraging the power of <a id="lik" href="#">software development</a>,<strong> software mantainance & support</strong>, <a id="lik" href="#">search engine marketing/digital marketing </a>,
              <a id="lik" href="#"> website solutions</a> and <strong> business automations</strong>.
            </p>
          </div>
        </div>


      </div>

      <div className="container-fluid call-bg">

        {/* <!-- Section Tittle --><p>
                    There are hundreds, maybe even thousands of website designers in Cape Town and it is often very difficult for you to know which web design company to go for. You may think “I need to find website designers near me” or finding affordable website designers is your priority.It is important that your design communicates the correct information, and that the user-experience is a seamless one.s a framework to help understand our client's or prospect's needs, and their business problem so that we can properly architect an appropriate solution.
                    Need an affordable web designer or web development company to accurately represent your brand? Look no further! Click below to get a web design quote today. </p>
                 */}
        <div className="section-tittle call-action text-center mt-5 mb-60">
          <h2 className="text-white">
            Want to know more about our Google Ads services? </h2>

          <a href="https://calendly.com/mushaitapiwanashe/briefing-session" className="border-btn2">
            Book A Free Briefieng Session
          </a>
        </div>

      </div>


      <div className="container categories-area ">
        <div className="section-tittle text-center mt-35 mb-35">
          <span>Our Services</span>
        </div>
        <div className="row mt-50 mb-100 cont-center">
          <div className="col-md  ">
            <img
              src="assets/img/services/web.jpg"
              height="200"
              width="200"
              alt=""
            />
          </div>
          <div className="col-md align-items-center  cont-center">
            <h2>Google Ads Campaign Auditing</h2>
            <p className="text-left">
              Already have existing Google Ads campaigns that aren’t converting? We’ll provide you with a deep analysis of your Google Ads campaign’s performance and look at improvements that can be made to give that performance a much-needed boost.
            </p>
            <a href="services" className="border-btn">
              FullStack Development
            </a>
            <a href="services" className="border-btn">
              Blogs
            </a>
            <a href="services" className="border-btn">
              Wordpress
            </a>
            <a href="services" className="border-btn">
              Website Development
            </a>
            <a href="services" className="border-btn">
              E-Commerce{" "}
            </a>
          </div>
        </div>
        <div className="row mt-50 mb-150">
          <div className="col-md align-items-center text-sm-center cont-center">
            <h2>Campaign Setup</h2>
            <p className="text-left">
              We take care of the end-to-end setup of a wide range of Google Ads campaigns.
              We are experts in setting up various successful and profitable campaigns, including search ads, product listing ads (PLAs or Google Shopping ads), display ads, remarketing ads, RLSAs (Remarketing Lists for Search ads) and YouTube ads.
            </p>
            <a href="services" className="border-btn">
              Database Design
            </a>
            <a href="services" className="border-btn">
              Database Integration
            </a>
            <a href="services" className="border-btn">
              Database Development
            </a>
            <a href="services" className="border-btn">
              {" "}
              Custom API Development
            </a>
            <a href="services" className="border-btn">
              Database Management
            </a>
            <a href="services" className="border-btn">
              Database Migration
            </a>
            <a href="services" className="border-btn">
              Database Maintenance
            </a>
          </div>
          <div className="col-md text-md-center text-center text-sm-center cont-center">
            <img
              className="mt-80"
              src="assets/img/services/database.png"
              height="200"
              width="200"
              alt=""
            />
          </div>
        </div>

        <div className="row mt-50 mb-150">
          <div className="col-md cont-center ">
            <img
              src="assets/img/services/coder.jpg"
              height="200"
              width="200"
              alt=""
            />
          </div>
          <div className="col-md cat-cap  text-center cont-center ">
            <h2>Campaign Management</h2>
            <p className="text-left">
              Whether you already have a set of Google Ads campaigns that need some TLC or you need a completely new set of campaigns creating, we can regularly manage your campaigns for maximum profitability.
            </p>
            <a href="services" className="border-btn">
              IOs Development
            </a>
            <a href="services" className="border-btn">
              Android development
            </a>
          </div>
        </div>
        <div className="row mt-50 mb-150">
          <div className="col-md cont-center ">
            <img
              src="assets/img/services/coder.jpg"
              height="200"
              width="200"
              alt=""
            />
          </div>
          <div className="col-md cat-cap  text-center cont-center ">
            <h2>Landing Page Development</h2>
            <p className="text-left">
              Every time we see Google Ads directing people to weak landing pages, we recoil. For ad campaigns to get real revenue-increasing results, they need to be accompanied by compelling landing page copy. We take landing pages seriously – We are after all also one of the UK’s leading SEO Agencies.
              Our expert Copywriters can write landing pages that capture your brand voice and values. With off-the-chart, impressive copywriting, they’ll get through to your visitors on a deeper level and — most importantly — sell your products.
            </p>
            <a href="services" className="border-btn">
              IOs Development
            </a>
            <a href="services" className="border-btn">
              Android development
            </a>
          </div>
        </div>
        <div className="row mt-50 mb-30">
          <div className="col-md cat-cap text-center cont-center ">
            <h2>Ad Copy Creation</h2>
            <p className="text-left">
              We write super-effective Google Ads copy that sums up your business and your brand’s tone of voice and encourages people to click through to your website.
              We’ll also regularly test ad performance, tweaking the text or adding new variations to ensure we constantly remain ahead of the game.
            </p>
            <a href="services" className="border-btn">
              UI/UX Design
            </a>
            <a href="services" className="border-btn">
              Website Design
            </a>
            <a href="services" className="border-btn">
              App Design
            </a>
            <a href="services" className="border-btn">
              Flyers
            </a>
            <a href="services" className="border-btn">
              Logo
            </a>
            <a href="services" className="border-btn">
              Business Cards
            </a>
          </div>
          <div className="col-md text-md-center text-center cont-center">
            <img
              className="mx-auto d-block"
              src="assets/img/services/web.jpg"
              height="200"
              width="200"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="container-fluid bg-gray" id="accordion-style-1">
        <div class="container">
          <section>
            <div class="row">
              <div class="col-12">
                <h1 class="text-black mb-4 text-center ">Frequently Asked Questions</h1>
              </div>
              <div class="col-10 mx-auto">
                <div class="accordion" id="accordionExample">
                  <div class="card">
                    <div class="card-header" id="headingOne">
                      <h5 class="mb-0">
                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          <i class="fa fa-amazon main"></i><i class="fa fa-angle-double-right mr-3"></i> What ARe Google Ads
                        </button> </h5>
                    </div>

                    <div id="collapseOne" class="collapse show fade" aria-labelledby="headingOne" data-parent="#accordionExample">
                      <div class="card-body">
                        Anyone with the means to spend money online can use Google AdWords, a highly focused kind of advertising. Even the newest company can compete with major international brands for a spot on Google’s main page.

                        You can purchase ads through Google Ads or Paid Search Campaigns to increase traffic to your website. Due to the fact they’re paid for, they are separate from organic search results. Organic search results are not purchased outright						</div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingTwo">
                      <h5 class="mb-0">
                        <button class="btn btn-link collapsed btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          <i class="fa fa-plus main"></i><i class="fa fa-angle-double-right mr-3"></i>Will my ad appear first in the pages if i pay more
                        </button>
                      </h5>
                    </div>
                    <div id="collapseTwo" class="collapse fade" aria-labelledby="headingTwo" data-parent="#accordionExample">
                      <div class="card-body">
                        Possibly. An auction method is used for Google Ads. The automated bidding begins when a user searches for a keyword. You’ll likely show up first if your bid is the highest. However, this is not to say that you should constantly aim to be first. Cost per click, daily budget, and impression share are all balanced.							</div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingFive">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                              <i class="fa fa-envelope main"></i><i class="fa fa-angle-double-right mr-3"></i>Should I use Google Ads services to help me gain leads and sales?
                            </button>
                          </h5>
                        </div>
                        <div id="collapseFive" class="collapse fade" aria-labelledby="headingFive" data-parent="#accordionExample">
                          <div class="card-body">
                            Google Ads, can be incredibly beneficial if you’re looking to drive traffic to your site, and here’s why:

                            The number one benefit of Google Ads is increased web traffic. Whether or not your potential customers convert once they’re on your site, you’ve successfully convinced them to click your link, which creates the potential for future conversions – you’re on your way to having more leads!
                            Even if searchers don’t click on your link right away, your name will still be at the top of Google, creating visibility for potential future customers.
                            After you’ve paid for the initial bid, you won’t be charged unless someone clicks and converts on your ad.
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header" id="headingFive">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                              <i class="fa fa-envelope main"></i><i class="fa fa-angle-double-right mr-3"></i>Are Google Ads better than SEO?
                            </button>
                          </h5>
                        </div>
                        <div id="collapseFive" class="collapse fade" aria-labelledby="headingFive" data-parent="#accordionExample">
                          <div class="card-body">
                          There’s no easy answer to this question. It mostly depends upon your goals, budget and time available. Try not to think of it as one being better than the other, but rather as two different marketing strategies that can be beneficial to your business for different reasons. 

There’s no doubt that SEO has lots of advantages, and it is something that any business can benefit from if done correctly. However, paid search cuts out a lot of the labour that comes with SEO. With Google Ads, you can:

Jump to the front of Google rankings without having to optimise your content or invest in technical SEO.
Create immediate search engine visibility for your business.
Have measurable ROI, and you won’t have to spend unless people convert on your ad.
                          </div>
                        </div>
                      </div>
                    <div class="card">
                      <div class="card-header" id="headingThree">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <i class="fa fa-expeditedssl main"></i><i class="fa fa-angle-double-right mr-3"></i>What’s The Difference Between SEO and Google Ads?
                          </button>
                        </h5>
                      </div>
                      <div id="collapseThree" class="collapse fade" aria-labelledby="headingThree" data-parent="#accordionExample">
                        <div class="card-body">
                          SEO = you pay us a small monthly fee to rank your website as HIGH as possible organically (It takes time but it’s the most cost effective, and most effective, marketing strategy).

                          Google Ads = you pay Google each time someone clicks on your ad (Quick, but expensive).

                          If you switch your Google Ads off, all traffic are gone. With SEO, the work we do for you stays forever, it’s an investment into your website and business.								</div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingFour">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            <i class="fa fa-envelope main"></i><i class="fa fa-angle-double-right mr-3"></i>What is the minimum budget on Google Ads
                          </button>
                        </h5>
                      </div>
                      <div id="collapseFour" class="collapse fade" aria-labelledby="headingFour" data-parent="#accordionExample">
                        <div class="card-body">
                          There is no minimum expenditure for Google Ads; you are free to start with 20 rand per day and gradually increase your budget if you wish. However, your budget will have a significant impact on how frequently and where your ad is displayed on Google.

                          Despite the fact that you may still have a successful campaign even if you don’t rank first, the key is to react to the data and achieve the right balance.							</div>
                      </div>
                      <div class="card">
                        <div class="card-header" id="headingFive">
                          <h5 class="mb-0">
                            <button class="btn btn-link collapsed btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                              <i class="fa fa-envelope main"></i><i class="fa fa-angle-double-right mr-3"></i>What are the different kinds of Google Ads
                            </button>
                          </h5>
                        </div>
                        <div id="collapseFive" class="collapse fade" aria-labelledby="headingFive" data-parent="#accordionExample">
                          <div class="card-body">
                            Search advertising, display advertising, and shopping advertisements are the three primary subtypes of Google Ads.

                            You can insert online ads into search engine results using the method of search advertising. Display advertising is the promotion of a good or service using images, videos, and some text. These advertisements can be seen as banners on other websites, as well as on applications, social media, and videos. Shopping ads only advertise a product using details like photos, the name of the product, and pricing. Google Shopping, Google Search, Google Images, and other websites can also display these ads.
                          </div>
                        </div>
                      </div>
                     
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingSix">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseFour">
                            <i class="fa fa-envelope main"></i><i class="fa fa-angle-double-right mr-3"></i>How do i know that my google Ads don't appear for wrong keywords
                          </button>
                        </h5>
                      </div>
                      <div id="collapseSix" class="collapse fade" aria-labelledby="headingSix" data-parent="#accordionExample">
                        <div class="card-body">
                          When using Google Ads for the first time, this can often go wrong, but taking the time to understand where you want to meet your customers in their purchasing process and their search intent can help you lay the groundwork for successful Google Ads.

                          You can utilise the keyword match system to draw in relevant keywords and the negative keyword system to sway irrelevant ones, ensure your Google Ad is functioning optimally.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingSeven">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseFour">
                            <i class="fa fa-envelope main"></i><i class="fa fa-angle-double-right mr-3"></i>What is PPC, and is it better than SEO?
                          </button>
                        </h5>
                      </div>
                      <div id="collapseSeven" class="collapse fade" aria-labelledby="headingSeven" data-parent="#accordionExample">
                        <div class="card-body">
                          PPC means Pay Per Click. It is a type of internet marketing where you pay a certain fee when your content ad is clicked on. PPC is considered a short-term strategy because it relies on pay per click. Conversely, SEO has more upfront cost but is a good long-term strategy because it gives consistent results with time.
                        </div>
                      </div>
                    </div>
                  </div>
                </div></div>
            </div>
          </section>
        </div>
      </div>
      {/* <!-- Great Stuffs End -->
  <!-- More about info --> */}
      <section
        className="collection section-img-bg2  section-over1"
        data-background="assets/img/gallery/section_bg01.jpg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="single-question text-center">
                {/* <!-- Video icon --> */}
                <div className="video-icon mb-40">
                  <Link
                    className="popup-video btn-icon"
                    to="/" //https://www.youtube.com/watch?v=up68UAfH0d0"
                    data-animation="bounceIn"
                    data-delay=".4s"
                  >
                    <i className="fas fa-play"></i>
                  </Link>
                </div>
                <h2
                  className="wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay=".1s"
                >
                  Digital Innovation Agency for your Business & Startup
                </h2>
                <Link
                  to="/about"
                  className="white-btn wow fadeInUp"
                  data-wow-duration="2s"
                  data-wow-delay=".4s"
                >
                  Get Started Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- about info End --> */}
    </main>
  );
};

export default GoogleAds;
